function _getStoredState() {
  var result = {
    queue: [],
  };

  var stored_state = sessionStorage.getItem("testing_history");
  if (stored_state) {
    stored_state = JSON.parse(stored_state);

    result = { ...result, ...stored_state }; // overwrite the defaults with the stored State
  }

  return result;
}

function _storeState(state) {
  try {
    sessionStorage.setItem("testing_history", JSON.stringify(state));
  } catch (ex) {
    console.log("Err: Too much data for localstorage");
  }
}

export default function () {
  return {
    namespaced: true,
    state: _getStoredState(),
    mutations: {
      ADD_TO_QUEUE: function (state, obj) {
        if (state.queue.length < 5) {
          state.queue.push(obj);
        } else {
          state.queue.shift(); // remove the first object
          state.queue.push(obj); // add the new object
        }
        _storeState(state);
      },
    },
    actions: {
      addToQueue: function ({ commit }, code, status, id) {
        commit("ADD_TO_QUEUE", { code: code, status: status, id: id });
      },
    },
    getters: {
      getQueue: (state) => state.queue,
    },
  };
}
